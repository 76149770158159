import * as React from 'react'
import {Link} from "gatsby";

export default function Footer({children}) {
    return (
        <div className="bg-dark text-light p-4">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <h4>Hilf uns noch besser zu werden</h4>
                        <ul>
                            <li><Link to="/shop-melden" className="text-light">Shop melden</Link></li>
                            <li><Link to="/fehler-melden" className="text-light">Fehler melden</Link></li>
                        </ul>
                    </div>
                    <div className="col-sm-6">
                        <h4>Links</h4>
                        <ul>
                            <li><Link to="/impressum" className="text-light">Impressum</Link></li>
                            <li><Link to="/datenschutz" className="text-light">Datenschutz</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}