import * as React from 'react'
import {graphql, Link, useStaticQuery} from "gatsby";
import {getShopURL} from "../logic/seo";
import {inTime} from "../logic/dateComparisons";

export default function FrontPageSidebar() {
    const data = useStaticQuery(graphql`
    query HighlightedShops {
  allStrapiStores(filter: {highlighted: {eq: true}}) {
    nodes {
      name
      url
      urlAffiliate
      urlPremium
      slug
      lastOrderTimePremiumDiff: lastOrderTimePremium(fromNow: true, locale: "de")
      lastOrderTimePremium
      lastOrderTime
    }
    totalCount
  }
  }
    `)


    const deliveryCount = data.allStrapiStores.nodes.filter(item => {
        return inTime(item.lastOrderTime, Date.now())
    }).length
    const premiumDeliveryCount = data.allStrapiStores.nodes.filter(item => {
        return inTime(item.lastOrderTimePremium, Date.now())
    }).length

    const totalCount = data.allStrapiStores.totalCount

    return (
        <div className="">
            <div>
                <h3>Über uns</h3>
                <p>Wir lieben Weihnachten, von der Dekoration bis zum Essen, alles daran. Aber bei so viel zu tun und so
                    wenig Zeit, ist diese Jahreszeit immer mit viel Stress verbunden. Aus diesem Grund erstellen wir
                    seit kurzem Übersichten um unseren Lesern etwas zurück zugeben und Stress zu ersparen.</p>
            </div>

            <div>
                <h3>Beliebte Onlineshops</h3>
                In Klammern findet sich der letzmögliche Bestelltermin.
                <ul>
                    {data.allStrapiStores.nodes.map((item) => {
                        return (
                            <li><a href={getShopURL(item.slug)}>{item.name}</a> ({item.lastOrderTimePremiumDiff})</li>)
                    })}
                </ul>
            </div>

            <div>
                <h3>Gesamtübersicht</h3>
                <div className="progress">
                    <div className="progress-bar progress-bar-striped bg-success" role="progressbar"
                         style={{width: (deliveryCount/totalCount)*100 + "%"}}
                         aria-valuenow={deliveryCount} aria-valuemin="0" aria-valuemax={totalCount}></div>
                    <div className="progress-bar progress-bar-striped bg-info" role="progressbar" style={{width: ((premiumDeliveryCount-deliveryCount)/totalCount)*100 + "%"}}
                         aria-valuenow={premiumDeliveryCount} aria-valuemin="0" aria-valuemax={totalCount}></div>
                </div>

                <p>{deliveryCount} von {totalCount} Onlineshops liefern noch vor Weihnachten mit normalem Versand.</p>
                <p>{premiumDeliveryCount} von {totalCount} Onlineshops liefern noch vor Weihnachten mit Premiumversand.</p>


            </div>
        </div>
    )
}