import * as React from "react"
import DefaultLayout from "../layouts/default";
import NotifyError from "../components/notify-error";
import {useLocation} from '@reach/router';
import AffiliateNotice from "../components/affiliate-notice";
import CurrentStatus from "../components/store/current-status";
import {dateDeliveryStatus, inTime, storeDeliveryStatus} from "../logic/dateComparisons";
import { Helmet } from "react-helmet"
import {graphql, useStaticQuery} from "gatsby";
import ReactMarkdown from "react-markdown";
import NotConfirmedNotice from "../components/not-confirmed-notice";



export default function Store_template({pageContext: {store, year}}) {
    const meta = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          formats {
            timeFormat {
                hour
                minute
            }
            dateTimeFormat {
                hour
                minute
                day
                month
                year
            }
        }
        }
        
      }
    }
  `)

    const lastOrderDate = new Date(store.lastOrderTime)
    const lastOrderPremiumDate = new Date(store.lastOrderTimePremium)

    return (
        <DefaultLayout>
            <Helmet>
                <title>{store.title || store.name} | {meta.site.siteMetadata.title}</title>
            </Helmet>

                <CurrentStatus store={store}></CurrentStatus>
                <h1>{store.headlineH1 || "Lieferfristen von " + store.name + " zu Weihnachten " + {year}}</h1>
                <div className="row">
                    {store.icon.url && <div className="col-sm-6 my-auto">
                        <figure className="figure">
                            <a href={store.url}><img className="img-fluid figure-img" src={process.env.IMAGE_BASE_URL+store.icon.url} alt={store.icon.alternativeText}/></a>
                            <figcaption className="figure-caption text-center">Logo von {store.name}</figcaption>
                        </figure>
                    </div>}

                    <div className={store.icon.url ? "col-sm-6" : "col-sm-12"}>
                        <h2>Letztmöglicher Bestellzeitpunkt</h2>
                        <table className="table">
                            <tbody>
                            {store.lastOrderTimePremium &&
                            <tr>
                                <td scope="row">
                                    Für Kunden mit <span className="fw-bold">{(store.urlPremium && <a href={store.urlPremium}>{store.premiumName}</a>) || store.premiumName}</span>
                                </td>
                                <td className={dateDeliveryStatus(lastOrderDate, Date.now())}>
                                    <span className='fw-bold'>{lastOrderPremiumDate.toLocaleDateString()+" " + lastOrderPremiumDate.toLocaleString([], meta.site.siteMetadata.formats.timeFormat)}</span>
                                </td>
                            </tr>
                            }
                            <tr>
                                <td scope="row">
                                    Für normale Kunden
                                </td>
                                <td className={dateDeliveryStatus(lastOrderPremiumDate, Date.now())}>
                                    <span className='fw-bold'> {lastOrderDate.toLocaleDateString()+" " + lastOrderDate.toLocaleString([], meta.site.siteMetadata.formats.timeFormat)}</span>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                        <p className='text-center'>
                            <a href={store.urlAffiliate || store.url} className="btn btn-primary">Noch rechtzeitig bei {store.name} bestellen</a>

                        </p>
                    </div>


                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <ReactMarkdown>{store.text}</ReactMarkdown>
                        <h3>Welche Produkte bietet {store.name} an?</h3>
                        <p>{store.products}</p>
                    </div>


                </div>

                <h4>Bemerkungen und Fehlerhinweise</h4>
                {store.confirmed || <NotConfirmedNotice/>  }
                {(store.urlAffiliate) && <AffiliateNotice/>}
                <NotifyError currentLocation={useLocation().href}></NotifyError>

        </DefaultLayout>
    )
}