import * as React from 'react'
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import SidebarLayout from "./sidebar";

export default function DefaultLayout({children, isIndex = false}) {

    return (
        <div className="" style={{backgroundImage: 'url(/images/christmas-trees.svg)', backgroundSize: '200px'}}>
            <NavBar></NavBar>
            {isIndex && <div className="bg-image" style={{
                backgroundImage: "url(images/christmas.jpg)",
                backgroundSize: "100%",
                height: "25vh"
            }}
            ></div>}
                <SidebarLayout>
                    {children}
                </SidebarLayout>
            <Footer></Footer>
        </div>
    )
}
