import * as React from 'react'
import DefaultLayout from "../layouts/default";

interface IndexPageProps {
}

export default class IndexPage extends React.Component<IndexPageProps, {}> {
    public render() {
        return (
            <div>
                <DefaultLayout>
                    <div className="bg-image" style={{
                        backgroundImage: "url(images/christmas.jpg)",
                        backgroundSize: "100%",
                        height: "25vh"
                    }}
                    >
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8">
                                <h1>Lieferfristen Weihnachten 2021</h1>
                                <p>Bei uns finden Sie schnell und leicht heraus bis wann Sie ihre Geschenke bei welchem
                                    Onlineshop
                                    bestellen müssen, damit diese noch rechtzeitig vor Weihnachten geliefert werden.</p>

                                <p>Wir befinden uns derzeit noch im Aufbau, weshalb noch nicht alle deutschen Onlineshops auf unserer Plattform vertreten sind.</p>
                                <p>Wenn sie uns beim Aufbau unserer Plattform helfen möchten so können sie hier einen neuen Shop melden.</p>

                                <p>Text mit Besinnlichkeit von Schenken</p>

                                <p>Hier kommen sie zur Übersichtseite</p>
                                <p>Hier geht es direkt zu den Seiten der größten OnlineShops</p>
                            </div>
                            <div className="col-sm-4">
                                <div>
                                    <h2>Über uns</h2>

                                </div>

                                <div>
                                    <h2>Noch rechtzeitig vor Weihnachten liefern:</h2>
                                    <ul>
                                        <li>Amazon</li>
                                    </ul>
                                </div>

                                <div>
                                    <h2>Kategorien</h2>
                                </div>

                            </div>


                        </div>
                    </div>



                </DefaultLayout>
            </div>

        )
    }
}