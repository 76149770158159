import * as React from 'react'
import DefaultLayout from "../layouts/default";

interface IndexPageProps {
}

export default class IndexPage extends React.Component<IndexPageProps, {}> {
    public render() {
        if (typeof window !== `undefined`) {
            const paperform = require("../../static/js/paperform")
        }
        return (
            <div>
                <DefaultLayout>
                    <div className="bg-image" style={{
                        backgroundImage: "url(images/christmas.jpg)",
                        backgroundSize: "100%",
                        height: "25vh"
                    }}
                    >
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8">
                                <h1>Shop melden</h1>
                                <p>Aktuell befinden wir uns noch im Aufbau unserer Plattform und freuen uns über Ihre Vorschläge zu entsprechenden Online-Shops.</p>
                                <div data-paperform-id="w7tbfjif"></div>
                            </div>
                            <div className="col-sm-4">
                                <div>
                                    <h2>Über uns</h2>

                                </div>

                                <div>
                                    <h2>Noch rechtzeitig vor Weihnachten liefern:</h2>
                                    <ul>
                                        <li>Amazon</li>
                                    </ul>
                                </div>

                                <div>
                                    <h2>Kategorien</h2>
                                </div>

                            </div>


                        </div>
                    </div>



                </DefaultLayout>
            </div>

        )
    }
}