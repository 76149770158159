import * as React from 'react'
import DefaultLayout from "../layouts/default";
import {graphql, Page} from "gatsby";
import ReactMarkdown from 'react-markdown';


export default class IndexPage extends React.Component<Page, { props }> {

    public render() {
        return (

            <DefaultLayout isIndex={true}>
                <ReactMarkdown>{this.props.data.strapiHome.text}</ReactMarkdown>

            </DefaultLayout>

        )
    }
}

export const query = graphql`
query home {
  strapiHome {
    text
  }
}
`
